import React from "react";
import Header from "../../components/Landers/Header";
import SEO from "../../components/Landers/SEO";
import Brands from "../../components/Landers/Brands";
import GetInTouch from "../../components/ContactUs/GetInTouch";
import Testimonials from "../../components/Landers/Testimonials";
import Footer from "../../components/Landers/Footer";
import styles from "../../components/Landers/landers.module.scss";

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={styles.solutions}>
    <SEO
      title="Contact us"
      desc="Thank you for trusting SpringVerify. Our product specialist will be reaching out to you soon to discuss your requirements."
      url="https://in.springverify.com/contact-us/"
      img_url=""
    />
    <div className={`${styles.topSection} ${styles.contactHeader}`}>
      <Header />
    </div>
    <GetInTouch />
    <Brands />
    <Testimonials testimonials={testimonials} path="Employment" />
    <Footer />
  </div>
);
