import * as Sentry from "@sentry/browser";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getErrorMessage } from "../../../utils/utilities";
import { submitForm } from "../api";
import styles from "../core.module.scss";
import cookie from "react-cookies";

const Input = () => {
  const [name, setName] = useState(""),
    [email, setEmail] = useState(""),
    [phone, setPhone] = useState(""),
    [company, setCompany] = useState(""),
    [error, setError] = useState(""),
    [submitting, setSubmitting] = useState(false);

  const handlePhoneInput = (value) => {
    setPhone(value);
  };

  const handleErrorChange = (key) => {
    if (error[key]) {
      const newErrorObj = { ...error };
      newErrorObj[key] = "";
      setError(newErrorObj);
    }
  };

  const validateInputs = () => {
    const tempError = {};

    if (!name) {
      tempError["name"] = "Name is required";
    }
    if (
      !email ||
      !email.match(
        "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)+$"
      )
    ) {
      tempError["email"] = "Invalid email";
    }
    if (!phone || !/^\d{10}$/.test(phone)) {
      tempError["phone"] = "Mobile number is required";
    }

    if (!company) {
      tempError["company"] = "Company name is required";
    }

    setError(tempError);

    if (JSON.stringify(tempError) !== "{}") {
      return false;
    }
    return true;
  };

  const callSubmitAPI = (query) => {
    submitForm(query)
      .then((response) => {
        setSubmitting(false);
        toast.success("Form submitted Successfully");
        if (typeof window !== `undefined`) {
          window.location.href = `${process.env.GATSBY_SV_IN_LANDING}thank-you/`;
        }
      })
      .catch((er) => {
        setSubmitting(false);

        if (typeof window !== `undefined`) {
          if (
            !window.location.href.includes("localhost") &&
            !window.location.href.includes("dev.in.springverify")
          ) {
            Sentry.captureException(er);
          }
        }

        er.response.data.message && toast.error(er.response.data.message);
      });
  };

  const submitFormData = () => {
    if (validateInputs()) {
      setSubmitting(true);
      const payload = {
        name,
        email,
        phone: phone,
        company,
      };

      if (typeof window !== `undefined` && typeof document !== `undefined`) {
        payload.utm_source =
          new URLSearchParams(document.location.search).get("utm_source") ||
          cookie.load("utmSource");
        payload.utm_medium =
          new URLSearchParams(document.location.search).get("utm_medium") ||
          cookie.load("utmMedium");
        payload.utm_campaign =
          new URLSearchParams(document.location.search).get("utm_campaign") ||
          cookie.load("utmCampaign");
        payload.keyword =
          new URLSearchParams(document.location.search).get("keyword") || cookie.load("utmKeyword");
        payload.url_query_string = window.location.search
          ? window.location.search.replace("?", "")
          : cookie.load("urlQueryString");
      }

      callSubmitAPI(payload);
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.HeaderRight}>
        <div className={styles.HeaderForm}>
          <>
            <div className={styles.HeaderFormInput}>
              <input
                placeholder="Name *"
                type="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  handleErrorChange("name");
                }}
              />
            </div>
            <span className={styles.info}>{error?.name}</span>
          </>
          <>
            <div className={styles.HeaderFormInput}>
              <input
                placeholder="Phone *"
                type="phone"
                value={phone}
                onChange={(e) => {
                  handlePhoneInput(e.target.value);
                  handleErrorChange("phone");
                }}
              />
            </div>
            <span className={styles.info}>{error?.phone}</span>
          </>
          <>
            <div className={styles.HeaderFormInput}>
              <input
                placeholder="Email ID *"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleErrorChange("email");
                }}
              />
            </div>
            <span className={styles.info}>{error?.email}</span>
          </>
          <>
            <div className={styles.HeaderFormInput}>
              <input
                placeholder="Company Name *"
                type="text"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  handleErrorChange("company");
                }}
              />
            </div>
            <span className={styles.info}>{error?.company}</span>
          </>

          <div className={styles.HeaderFormButton}>
            {!submitting ? (
              <button onClick={() => submitFormData()}>Send</button>
            ) : (
              <div className={styles.spinner}>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Input;
