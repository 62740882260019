import React from "react";
import Phone from "../../../images/Contact/phone.svg";
import Email from "../../../images/Contact/email.svg";
import ISO from "../../../images/Contact/iso.png";
import Badge from "../../../images/Contact/badge.png";
import Input from "../../CoreComponents/Input";
import styles from "./GetInTouch.module.scss";
import { Helmet } from "react-helmet";

const GetInTouch = () => {
  return (
    <>
      <Helmet>
        <title>Contact us | SpringVerify India</title>
        <meta
          name="description"
          content="Thank you for trusting SpringVerify India. Our product specialist will be reaching out to you soon to discuss your requirements."
        />
        <link
          rel="canonical"
          href="https://in.springverify.com/contact-us/"
        ></link>
        <meta property="og:site_name" content="SpringVerify India" />
        <meta property="og:title" content="Contact us | SpringVerify India" />
        <meta
          property="og:description"
          content="Thank you for trusting SpringVerify India. Our product specialist will be reaching out to you soon to discuss your requirements."
        />
        <meta
          property="og:url"
          content="https://in.springverify.com/contact-us/"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="image URL" />
        <meta property="og:image:secure_url" content="image URL" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        <meta
          property="twitter:title"
          content="Contact us | SpringVerify India"
        />
        <meta
          property="twitter:description"
          content="Thank you for trusting SpringVerify India. Our product specialist will be reaching out to you soon to discuss your requirements."
        />
        <meta property="twitter:card" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content="image URL" />
        <meta property="twitter:site" content="@springroleinc" />
      </Helmet>

      <div className={styles.GetInTouchWrapOuter}>
        <section>
          <div className={styles.GetInTouchWrap}>
            <div className={styles.GetInTouchLeft}>
              <div className={styles.GetInTouchContent}>
                <h2>Streamline your hiring process</h2>
                <p>
                  Fill out the form and our product specialist will get in touch
                  with you as soon as possible.{" "}
                </p>
              </div>
              <div className={styles.GetInTouchCardMob}>
                <h5>Tell us about your company</h5>
                <div className={styles.GetInTouchform}>
                  <Input />
                </div>
              </div>
              <div className={styles.GetInTouchContent}>
                <span>
                  <img src={Phone} />
                  <a href="tel:+919019906005">+91-9019906005</a>{" "}
                </span>
                <span>
                  <img src={Email} />
                  <a href="mailto:sales@springverify.com">
                    sales@springverify.com
                  </a>{" "}
                </span>
              </div>
              <div className={styles.GetInTouchBadges}>
                <img src={ISO} alt="SpringVerify" />
                <img src={Badge} alt="SpringVerify" />
              </div>
            </div>
            <div className={styles.GetInTouchRight}>
              <div className={styles.GetInTouchCard}>
                <h5>Get started with SpringVerify</h5>
                <Input />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GetInTouch;
